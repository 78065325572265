import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RootComponent from './RootComponent';
import { persistor, store } from './store/reducers/store';

import { SocketProvider } from './contexts/Socket';




const App: React.FC = () => {



    return (
        <Provider store={store}>
            <SocketProvider>

                <PersistGate loading={null} persistor={persistor}>
                    <RootComponent />
                </PersistGate>
            </SocketProvider>

        </Provider>
    )
}

export default App;
