import React, { createContext } from 'react';
import { io, Socket } from 'socket.io-client';

const backendAddress = process.env.REACT_APP_BACKEND_URL || "http://localhost:4005";
console.log('Backend Address', backendAddress);

const clientId = 'browser1';
const socket = io(backendAddress);
const SocketContext = createContext<Socket>(socket);


socket.on('connect', () => {
    console.log('Connected!');
    socket.emit('ui_client_connected', clientId);
});


const SocketProvider = ({ children }: any) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };
