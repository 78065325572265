import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'

import { SocketContext } from '../contexts/Socket';
import { store } from '../store/reducers/store';

const ReservatorTablets: React.FC = () => {
    const [tabletinfo, setTabletinfo] = useState([]);

    const socket = useContext(SocketContext);

    
    function removeBlinkFromObject(deviceId: string) {
        setTabletinfo((previousInfo: any) => {
            const newState = previousInfo.map((tabletInfo: any) => {
                if (tabletInfo['id'] === deviceId) {
                    return {
                        ...tabletInfo, changed: false
                    };
                } else {
                    return tabletInfo;
                }
            });
            return newState;
        });
        return;
    };


    function updateObject(device_info: any) {

        setTabletinfo((previousInfo: any) => {
            if (previousInfo.some((e: any) => e.id === device_info.id)) {
                
                // Did contain
                const newState = previousInfo.map((tabletInfo: any) => {
                    if (tabletInfo['id'] === device_info['id']) {
                        return {
                            ...device_info, changed: true
                        };
                    } else {
                        return {
                            ...tabletInfo, changed: false
                        };
                    }
                });
                return newState;

            } else {
                // Did not contain
                const newState = previousInfo;
                newState.push({ ...device_info, changed: true });
                return newState;
            }
        });

        return;
    }


    /**
     * On component render sets the date state to current date and time
     */
    useEffect(() => {

        // Pull device info from server always when rendering
        console.log('Pulling devices info from server');
        socket.emit('get_devices_info', 'reservator-tablet');

        socket.on("devices_info", (devices_info) => {
            const newDevicesInfo = [];
            for (let index = 0; index < devices_info.length; index++) {
                const tablet = devices_info[index];
                newDevicesInfo.push({...tablet, changed: false});
            }
            setTabletinfo(devices_info);
        });

        socket.on("device_info", (device_info) => {
            updateObject(device_info);
        });

        console.log('Rendered View')

    }, []);

   

    return (
        <div style={{ position: 'relative', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <table>
                <thead>
                    <tr>
                        <th>Tablet Identity</th>
                        <th>Status</th>
                        <th>Reservations fetched</th>
                        <th>Error message</th>
                    </tr>
                </thead>
                <tbody>
                    {tabletinfo.map((info, index) => {
                        
                        return (
                            <tr key={index} 
                                    className={info['changed'] ? 'blinkClass' : ''} 
                                    onAnimationEndCapture={(event) => {
                                        const target = event.target as HTMLInputElement;
                                        console.log('It over, byyhyy', target);
                                        removeBlinkFromObject(info['id']);
                            }} >
                                <td>{info['id']}</td>
                                <td className={info['connected'] ? 'online' : 'offline'}>{info['connected'] ? 'Connected' : 'Disconnected'}</td>
                                <td>{info['lastSeenOnRemoteServer']['dateTime']}</td>
                                <td>{info['errorMessage'] ? info['errorMessage']['message'] : 'N/A'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
};

export default ReservatorTablets;
